declare var require: any

export const environment = {
	appVersion: require('../../../../package.json').version + '-stage',
	environment_name: "stage",
	production: true,
	api_url: "https://accelerateapitest.consumeroptix.com/api/",
	quote_pdf_output: "http://accelerateapitest.consumeroptix.com/reports/*id*-lifequote-proposal.pdf",
	quote_video_url: "",
	stripe_key: "",
	share_video_url: "https://accelerateapitest.consumeroptix.com/Video.aspx",
	bugsnag_api_key: "0d1cb209e1326f1c5f0228db6a310919",
	base_url: "https://acceleratetest.consumeroptix.com/",
	mixpanel_api_token: null,
	mixpanel_api_proxy: null,
	share_dtc_url: "https://accelerateapitest.consumeroptix.com/Dtc.aspx",
};
